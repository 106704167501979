import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import PostGrid from "../components/global/PostGrid"
export default function Ingredient({ pageContext, data }) {
  const title = pageContext.Ingredient.replace(/^\/|\/$/g, "")

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={`Ingrediens ${title} | Gastrologiks opskrifter med ${title}`}
        description={`Hvordan bruger man ${title} i sin madlavning? Det kan du finde ud af her. Her er nemlig en oversigt over opskrifter der bruger ${title}`}
      />

      <div className="titleWrapper">
        <h1>Opskrifter med {title}</h1>
        <div className="spanLine"></div>
      </div>

      <PostGrid posts={data.allSanityRecipes.nodes} />
    </Layout>
  )
}

export const ingredientQuery = graphql`
  query ($Ingredient: String) {
    allSanityRecipes(filter: { ingredients: { regex: $Ingredient } }) {
      nodes {
        _rawBody

        title

        image: recipeImage {
          verticalImage {
            asset {
              gatsbyImageData(
                backgroundColor: "#f9f9f9"
                placeholder: NONE
                width: 280
                height: 366
              )
            }
          }
        }
        slug {
          current
        }
        time
      }
    }
  }
`
